.mainLayout {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'content sidebar';
    padding: 16px 12px 12px 12px;
    height: 100vh;
    min-height: 100vh;
    background: #F4F5F8;
    min-width: 0;

    // @media screen and (max-width: 480px) {
    //     grid-template-columns: none;
    //     grid-template-rows: min-content auto;
    //     grid-template-areas: 'sidebar' 'content';
    //     justify-items: end;
    //     gap: 20px;
    //     padding: 16px;
    // }

    // @media screen and (max-width: 991px) {
    //     grid-template-columns: none;
    //     grid-template-rows: min-content auto;
    //     grid-template-areas: 'sidebar' 'content';
    //     justify-content: center;
    //     justify-items: end;
    //     gap: 20px;
    //     padding: 16px;
    // }
}

.contentWrapper {
    grid-area: content;
    min-width: 0;
}

.mainSidebar {
    grid-area: sidebar;
    min-width: 0;
}

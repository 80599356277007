.titleInputWrapper {
    position: relative;
    flex: 1;
    min-width: 0;

    .titleInput {
        background: transparent;
        border: none;
        outline: none;
        font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
        color: var(--ant-color-text-secondary);
        width: 100%;
        padding: 0;
        margin: 0;
        line-height: 22px;
        font-size: 16px;
        font-weight: 500;
    }

    .limitIndicator {
        position: absolute;
        right: 0;
        top: calc(100% + 4px);
        font-size: 12px;
        padding: 2px 8px;
        border-radius: 4px;
        animation: fadeIn 0.2s ease;

        &.warning {
            background-color: #fffbe6;
            color: var(--ant-color-warning);
        }

        &.error {
            background-color: #fff2f0;
            color: var(--ant-color-error);
        }
    }
}

.projectTitle {
    //transition: all 0.2s ease;
    line-height: 28px !important;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    color: var(--ant-color-text-secondary);
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 24px;
    cursor: default;

    &.editable {
        cursor: text;

        &:hover::after {
            content: '􀦇';
            position: absolute;
            right: -3px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            opacity: 0.8;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    75% {
        transform: translateX(5px);
    }
}

.shake {
    animation: shake 0.2s ease-in-out;
}

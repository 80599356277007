.actionButton {
    color: var(--ant-color-text-secondary);
    background-color: transparent;
    font-size: 16px;
    transition: 0.4s;
    font-weight: 400;
    border: none;
    // border-bottom: none !important;
    box-shadow: none;
    padding: 4px 8px;
    cursor: pointer;
    animation: btnTransition 1s forwards;
    min-width: max-content;

    &:hover:not(:disabled) {
        color: var(--ant-color-primary);
    }

    &:disabled {
        opacity: 0.5 !important;
        cursor: not-allowed;
    }
}

.tabControlContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px !important;
}

/* Например, в вашем файле стилей */
.customOption {
    padding: 0px 0px;
    overflow-x: auto;
  }

.segmentedContainer {
  overflow-x: scroll;  /* включаем горизонтальный скролл */
  height: 44px;
}

.tabLabel {
  display: flex;
  align-items: center;
  gap: 6px;
}

.setupIcon {
  display: flex;
  align-items: center;
  overflow: visible;
  // stroke: currentColor;
  // stroke-width: 0.5px;
}
.respondentWrapper {
    width: 240px;
    min-width: 240px;

    .deleteBtn {
        opacity: 0;
        font-size: 12px;
        font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
        background-color: transparent;
        border: none;
        padding: 4px;
        cursor: pointer;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:hover {
            transition: 0.2s;
            color: var(--ant-color-error);
            background-color: rgba(106, 106, 112, 0.2);
        }
    }

    &:hover {
        .deleteBtn {
            opacity: 1;
        }
    }
}

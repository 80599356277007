.userAvatar {
    height: 52px;
    width: 52px;
    padding: 4px;
    background-color: white;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    }
}

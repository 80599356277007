.contentLayout {
    display: flex;
    align-items: center;
    height: 52px;
    gap: 20px;

    .link {
        background-color: white;
        border-radius: 50%;
        font-size: 14px;
        width: 52px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        flex-shrink: 0;
        &:hover {
            border: 1px solid var(--ant-color-primary);
        }
    }

    .titleDemo {
        font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 0;
    }

    .exportBtn {
        cursor: pointer;
        margin-left: auto;
    }
}

.projectTitle {
    color: var(--ant-color-text) !important;
    font-size: 24px !important;
    line-height: 28px !important;
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif !important;
    font-weight: 700 !important;
}

.combinedContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1;
}

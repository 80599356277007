.container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);
    position: relative;
    overflow: hidden;
}

.maxWidth {
    width: 100%;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.messagesContainer {
    flex: 1;
    height: calc(100% - 40px);
    overflow-y: auto;
    padding: 12px;
    margin-bottom: 60px;

    // Стили для браузеров на основе Webkit (Chrome, Safari)
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f8f8ff;
    }

    &::-webkit-scrollbar-thumb {
        background: #f8f8ff;
        border-radius: 3px;
    }

    // Стили для Firefox
    scrollbar-width: thin;
    scrollbar-color: #f8f8ff #f1f1f100;
}

.message {
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 8px;
    max-width: 80%;
}

.userMessage {
    background-color: #e6f7ff;
    margin-left: auto;
}

.assistantMessage {
    background-color: #f5f5f5;
    margin-right: auto;
}

.buttonStyle {
    // box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.01),
    //             0 2px 5px 0 rgba(0, 0, 0, 0.06);
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    background-color: var(--ant-color-primary);
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 999px;
    height: 45px;
    width: 45px;
    transition:
        background-color 0.3s,
        transform 0.2s;
    cursor: pointer;
    &:hover {
        background-color: #3A3A3D;
        // padding: 14px;
        // gap: 8px;
    }

    &:active {
        background-color: #d0c7ff;
        transform: scale(0.95);
    }
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputContainer {
    padding: 2px;
    border-radius: 14px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // background: white;
    max-width: 890px;
    margin: 0 auto;
    display: flex;
    gap: 8px;
    z-index: 100;
    align-items: flex-end;
                

    .ant-input-textarea {
        flex: 1;
    }
}

.tokenInfo {
    width: 100%;
    max-width: 890px;
    position: absolute;
    bottom: 50px;
    left: clamp(5px, 8%, 220px);
    font-size: 12px;
    color: #8c8c8c;
    font-style: italic;

    &::before {
        content: '*';
        margin-right: 4px;
        color: var(--ant-color-primary);
    }
}

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 800px;
    text-align: center;
    padding: 20px;
    color: #666;
}

.scrollToBottom {
    position: absolute;
    bottom: 100px;
    right: 20px;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: opacity 0.3s;
    z-index: 99;

    &:hover {
        background: #f5f5f5;
    }
}

.inputWrapper {
  flex: 1;
  display: flex;
//   box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.01),
//               0 2px 5px 0 rgba(0, 0, 0, 0.06);
}

.inputIconContainer {
    position: relative;
  }
  
  .globeIconInside {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
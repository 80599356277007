.linkTitle {
    color: var(--ant-color-text-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;

    .linkTitleCounter {
        margin-left: 8px;
        padding: 2px 6px;
        border-radius: 6px;
        font-size: 12px;
        font-weight: normal;
        background-color: rgba(106, 106, 112, 0.2);
    }

    &:hover {
        color: var(--ant-color-primary);

        .linkTitleCounter {
            background-color: rgba(106, 106, 112, 0.2);
        }
    }
}

.linkTitleActive {
    color: var(--ant-color-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;

    .linkTitleCounter {
        margin-left: 8px;
        padding: 2px 6px;
        border-radius: 6px;
        font-size: 12px;
        font-weight: normal;
        background-color: rgba(106, 106, 112, 0.2);
    }
}

.ant-modal-mask {
    backdrop-filter: blur(0.5px);
    background-color: #F4F5F8DD !important;
}

.ant-modal-content {
    background-color: #F4F5F8 !important;
}

.ant-modal-header {
    background: rgba(0, 0, 0, 0);
}

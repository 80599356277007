html,
body {
    margin: 0;
    background: #F4F5F8;
}

.ant-anchor-ink {
    display: none;
}

.ant-anchor-wrapper-horizontal::before {
    display: none;
}

.ant-collapse-content-box {
    padding-bottom: 12px !important;
}

.ant-anchor {
    margin-left: 11px !important; /* или нужное вам значение */
  }

.ant-card-body {
padding: 12px !important; /* или нужное вам значение */
gap: 0px !important;
}
  
.css-var-r1.ant-anchor-css-var{
--ant-anchor-link-padding-block: 0px !important; /* или нужное вам значение */
}

.css-var-r1.ant-upload-wrapper{
flex: auto;
}

.ant-upload-select {
width: 100% !important;
}

.ant-layout{
min-width: 0px !important;
}

/* Переопределяем скругление для всего контейнера segmented */
.ant-segmented {
    border-radius: 12px !important;
    height: 52px !important;
  }
  
  /* Если необходимо изменить скругление отдельных сегментов,
     можно добавить правило для их классов (например, .ant-segmented-item) */
.ant-segmented .ant-segmented-item {
    border-radius: 12px !important;
    height: 44px !important;
    display: flex;
    align-items: center;
    }

.ant-segmented-item-label {
padding: 0 12px !important;
}

.ant-segmented .ant-segmented-item:not(:last-child) {
    margin-right: 2px !important;
    }

.ant-tooltip-inner {
    border-radius: 16px !important;
    padding: 12px 24px !important;
}
.ant-popover-inner{
    border-radius: 16px !important;
    padding: 12px !important;
    width: fit-content !important;
}

.ant-dropdown {
    padding-top: 6px !important;
    /* width: fit-content !important; */
}

.ant-dropdown-menu {
    border-radius: 12px !important;
    padding: 12px !important;
    width: fit-content !important;
}
/* .ant-input {
    border-radius: 12px !important;
    background: #ffffffb2 !important; 
    backdrop-filter: blur(20px);        
    -webkit-backdrop-filter: blur(20px);
    }
     */
.headerLayout {
    display: grid;
    grid-template-columns: 240px 240px;
    align-items: center;
    align-content: baseline;
    justify-items: center;
    justify-content: center;
    row-gap: 16px;
    column-gap: 12px;
    padding-top: 135px;
    height: 576px;

    img {
        grid-column: span 2;
    }

    h1 {
        color: var(--ant-color-text-light-solid);
        font-weight: 600;
        font-size: 32px;
        margin: 0;
        grid-column: span 2;
    }

    p {
        color: rgba(239, 239, 241, 0.7);
        font-size: 24px;
        margin: 0;
        grid-column: span 2;
        text-align: center;
    }

    .headerBtn {
        width: 50%;
        background-color: rgba(255, 255, 255, 0.05);
        font-size: 14px;
        color: white;
        height: var(--ant-control-height-lg);
        border-radius: 14px;
        border: var(--ant-line-width) var(--ant-line-type) transparent;
        cursor: pointer;
        transition: all var(--ant-motion-duration-mid) var(--ant-motion-ease-in-out);
        grid-column: span 1;
        width: 100%;

        &:hover {
            border: 1px solid white;
        }
    }
}
